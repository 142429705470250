@import "src/scss/module";

.grid {
  &__button {
    display: block;
    background-color: $secondary;
    background-position: center;
    background-size: cover;
    position: relative;
    flex-basis: 160px;
    width: 100%;
    padding: 0;
    border: 0;
    color: $overlay-text-color;

    &:before {
      display: block;
      content: "";
      padding-top: 100%;
    }

    h5 {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      text-align: center;
      font-weight: $font-weight-base;
      text-shadow: 0 0 5px $dark;
    }
  }
}
